<template>
    <v-container fluid>
        <PageHeaderSlot :title="pageTitle" showBackButton>
            <template slot="bottom-right-slot">
                <v-btn text depressed class="primary ml-2" @click.prevent="save()" :loading="$store.getters.isLoading">儲存</v-btn>
            </template>
        </PageHeaderSlot>

        <v-row class="ma-0 align-stretch">
            <v-col class="pa-0 mb-4" cols="12" lg="6">
                <v-card class="course-info">
                    <v-card-title><span>課堂資訊</span></v-card-title>
                    <v-card-text class="pb-6 px-0">
                        <div class="course-code text-right pa-4 mb-6">課程編號：{{staticLessonInfo.course_code}}</div>
                        <v-form ref="classInfoForm" @submit.prevent>
                            <v-row class="ma-0">
                                <v-col class="py-0" cols="12" sm="6">
                                    <FormDatePicker
                                        label="日期"
                                        :dateValue.sync="formData.date"
                                        required
                                        disabledActionButtons
                                        dense
                                        disablePast
                                        dispatchUpdateOnChange
                                    ></FormDatePicker>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <label class="input-label">時間</label>
                                    <div class="d-flex justify-space-between">
                                        <FormSelect
                                            :fieldValue.sync="formData.start_time"
                                            required
                                            :options="startTimeOptions"
                                            dense
                                            @changed="formData.end_time = ''"
                                            dispatchUpdateOnChange
                                        ></FormSelect>
                                        <span class="body-2 colorBlack--text mt-3 mx-5">至</span>
                                        <FormSelect
                                                :fieldValue.sync="formData.end_time"
                                                required
                                                :options="endTimeOptions"
                                                dense
                                                :readonly="!$validate.DataValid(formData.start_time)"
                                                dispatchUpdateOnChange
                                        ></FormSelect>
                                    </div>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <FormSelect
                                        label="導師"
                                        :fieldValue.sync="formData.tutor_id"
                                        required
                                        :options="tutorOptions"
                                        dense
                                        dispatchUpdateOnChange
                                    ></FormSelect>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <FormSelect
                                        label="課室"
                                        :fieldValue.sync="formData.classroom_id"
                                        required
                                        :options="classroomOptions"
                                        dense
                                        dispatchUpdateOnChange
                                    ></FormSelect>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <FormFileUploader
                                        uploadText="上載教材"
                                        fileInputKey="upload_lectures"
                                        :files.sync="formData.attachment_list"
                                        acceptFormat="imageWithPDF"
                                        dispatchUpdateOnChange
                                    ></FormFileUploader>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col class="pa-0 pl-lg-4 mb-4" cols="12" lg="6">
                <Datatable
                    :isLoading="tableLoading"
                    :tableData="studentTableData"
                    :tableHeaders="studentTableHeaders"
                    disableFooter
                    disablePagination
                    tableName="學生"
                    tableEmptyText="沒有學生"
                    enableExport
                    @export-clicked="exportAttendance()"
                    noWrapToolbar
                >
                    <template v-slot:[`item.class_student_name`]="{ item }">
                        <div class="d-flex align-center">
                            <div class="gender-dot gender-m"></div>
                            <div style="white-space: nowrap;">{{ item.class_student_name }}</div>
                            <v-btn icon text depressed x-small :to="{name: 'StudentDetails', params: {id: item.id}}">
                                <v-icon class="fi fi-rr-eye mr-1" size="12" color="primary"></v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:[`item.attend_status`]="{ item }">
                        <v-radio-group
                            v-model="item.attend_status"
                            row
                            hide-details
                            class="ma-0"
                            dense
                            @change="()=>{
                                $store.dispatch('setDataIsUpdated', true);
                            }"
                        >
                            <v-radio class="mr-2" label="待定" value="pending"></v-radio>
                            <v-radio class="mr-2" label="出席" value="attend"></v-radio>
                            <v-radio class="mr-2" label="缺席" value="absent"></v-radio>
                        </v-radio-group>
                    </template>
                    <template v-slot:[`item.actionTransfer`]="{ item }">
                        <div class="d-flex justify-end">
                            <v-btn class="accent colorBlack--text" text depressed small @click="changeLesson('all', item.id)">轉堂</v-btn>
                            <v-btn class="accent colorBlack--text ml-2" text depressed small @click="changeLesson('single', item.id)">轉堂(單一課堂)</v-btn>
                            <v-btn class="accent colorBlack--text ml-2" text depressed small @click="dropCourse(item.id)">退課</v-btn>
                        </div>
                    </template>
                </Datatable>
            </v-col>
        </v-row>

        <Datatable
            tableName="課程其他課堂"
            tableEmptyText="沒有其他課堂"
            enableSelectAction
            enableSelectToggle
            :isLoading="otherLessonTableLoading"
            :tableData="otherLessonTableData"
            :tableHeaders="otherClassTableHeaders"
            disableFooter
            disablePagination
            @select-action-changes="handleSelectActionChange($event)"
            :selectedTableRow.sync="otherLessonSelectedRow" 
            @edit-clicked="handleEditOpen($event)"
            @delete-clicked="handDeleteLesson($event)"
        ></Datatable>

        <!-- Edit 其他課堂 Dialog -->
        <FormDialog
            title="課堂資訊"
            :maxWidth="680"
            ref="editFormDialog"
            formRef="editForm"
            @submit-clicked="handleEditLessonSubmit()"
            
        >
            <v-row class="ma-0">
                <v-col class="py-0" cols="12" sm="6">
                    <label class="input-label">時間</label>
                    <div class="d-flex justify-space-between">
                        <FormSelect
                            :fieldValue.sync="editFormData.start_time"
                            :required="editIsRequired"
                            :options="startTimeOptions"
                            dense
                            @changed="editFormData.end_time = ''"
                        ></FormSelect>
                        <span class="body-2 colorBlack--text mt-3 mx-5">至</span>
                        <FormSelect
                            :fieldValue.sync="editFormData.end_time"
                            :required="editIsRequired || $validate.DataValid(editFormData.start_time)"
                            :options="editEndTimeOptions"
                            dense
                            :readonly="!$validate.DataValid(editFormData.start_time)"
                        ></FormSelect>
                    </div>
                </v-col>
            </v-row>
            <v-row class="ma-0">
                <v-col class="py-0" cols="12" sm="6">
                    <FormSelect
                        label="導師"
                        :fieldValue.sync="editFormData.tutor_id"
                        :required="editIsRequired"
                        :options="tutorOptions"
                        dense
                    ></FormSelect>
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                    <FormSelect
                        label="課室"
                        :fieldValue.sync="editFormData.classroom_id"
                        :required="editIsRequired"
                        :options="classroomOptions"
                        dense
                    ></FormSelect>
                </v-col>
            </v-row>
        </FormDialog>


        <!-- 轉堂 Dialog -->
        <DialogLayout 
            :openDialog="changeLessonDialogOpen"
            :maxWidth="910"
            :title="changeLessonFilter.type === 'single' ? '轉堂(單一課堂)' : '轉堂'"
            hideAction
            enableScroll
            cardContentClass="pa-0"
            @close="changeLessonDialogOpen = false"
        >
            <div class="pa-4">
                <v-row class="ma-0 my-4">
                    <v-col cols="12" sm="6">
                        <Searchbar
                            label="搜尋課程"
                            :searchText.sync="changeLessonFilter.keyword"
                            searchPlaceholder="搜尋課程名稱／編號"
                            @search-clicked="onChangeLessonFilterChange()"
                            @search-clear="onChangeLessonFilterChange()"
                            :isSearching="isSearching"
                            :searchType.sync="changeLessonFilter.keywordType"
                            :searchTypeOptions="searchTypeOptions"
                            enableMultiTypeSearch
                            enabledClear
                        ></Searchbar>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="changeLessonFilter.type === 'all'">
                        <FormDatePicker
                            label="課堂日期"
                            :dateValue.sync="changeLessonFilter.dateRange"
                            isDateRange
                            @changed="onChangeLessonFilterChange()"
                            hideDetails
                            placeholder="選擇課堂日期"
                            dense
                        ></FormDatePicker>
                    </v-col>
                    <v-col cols="12" sm="6" v-else-if="changeLessonFilter.type === 'single'">
                        <FormDatePicker
                            label="課堂日期"
                            :dateValue.sync="changeLessonFilter.date"
                            @changed="onChangeLessonFilterChange()"
                            hideDetails
                            placeholder="選擇課堂日期"
                            dense
                        ></FormDatePicker>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <FormSelect
                            label="導師"
                            :fieldValue.sync="changeLessonFilter.tutor"
                            :options="changeLessonTutorOptions"
                            hideDetails
                            :readonly="isSearching"
                            placeholder="選擇導師"
                            dense
                            @changed="onChangeLessonFilterChange()"
                        ></FormSelect>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <FormSelect
                            label="年級"
                            :fieldValue.sync="changeLessonFilter.grade"
                            :options="$gradeOptions"
                            hideDetails
                            :readonly="isSearching"
                            placeholder="選擇年級"
                            dense
                            @changed="onChangeLessonFilterChange()"
                        ></FormSelect>
                    </v-col>
                </v-row>
            </div>

            <Datatable
                :isLoading="isSearching"
                :tableData="searchTableData"
                :tableHeaders="changeLessonHeaders"
                :itemTotal="searchTableTotal"
                :page="searchTableOptions.tablePage"
                :pageLimit="searchTableOptions.tableLimit"
                :pageLimitOptions="searchTableOptions.limitOptions"
                @apply-clicked="handleChangeLesson($event)"
                @options-update="onSearchTableOptionsChange($event)"
                tableEmptyText="沒有課堂"
            ></Datatable>
        </DialogLayout>

        <ConfirmDialog ref="exceedStudentDialog" title="是否轉堂？" :isDeleteDialog="false" enableShowRemindCheckbox>已到達課室學生上限，是否繼續轉堂？</ConfirmDialog>
        <ConfirmDialog ref="changeLessonRemindDialog" title="是否轉堂？" :isDeleteDialog="false" enableShowRemindCheckbox>是否確定要轉堂？</ConfirmDialog>
        <ConfirmDialog ref="dropCourseRemindDialog" title="是否退課？" :isDeleteDialog="false" enableShowRemindCheckbox>是否確定要退課？此動作無法復原。</ConfirmDialog>

        <ConfirmDialog ref="deleteOtherClassDialog" title="是否刪除所選課堂" deleteDescription="是否確定要刪除所選課堂？" enableShowRemindCheckbox></ConfirmDialog>
       
    </v-container>
</template>

<script>
import {
    mdiClose,
    mdiFileTableOutline
} from '@mdi/js'
import { mapActions } from 'vuex';
import FormDatePicker from '@/components/formField/FormDatePicker.vue';
import FormSelect from '@/components/formField/FormSelect.vue';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FormDialog from '@/components/FormDialog.vue';
import FormFileUploader from '@/components/formField/FormFileUploader.vue';
import DialogLayout from '@/components/layout/DialogLayout.vue';
import Searchbar from '@/components/Searchbar.vue';
import ExcelMixin from '@/mixins/ExcelMixin.vue';

export default {
    name: 'ClassDetails',
    mixins: [ExcelMixin],
    components: {
        FormDatePicker,
        FormSelect,
        Datatable,
        ConfirmDialog,
        FormDialog,
        FormFileUploader,
        DialogLayout,
        Searchbar
    },
    computed: {
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        pageTitle() {
            return `${this.staticLessonInfo.course_name} ${this.$validate.DataValid(this.staticLessonInfo.date) ? `${this.staticLessonInfo.date.substring(8,10)}/${this.staticLessonInfo.date.substring(5,7)}/${this.staticLessonInfo.date.substring(0,4)}（${this.$formatter.displayWeekday(this.staticLessonInfo.date)}）` : ''}`
        },
        endTimeOptions() {
            return this.$common.getEndTimeOptions(this.formData.start_time);
        },
        editEndTimeOptions() {
            return this.$common.getEndTimeOptions(this.editFormData.start_time);
        },
        timeSlotError() {
            return !this.$validate.DataValid(this.formData.start_time) || !this.$validate.DataValid(this.formData.end_time);
        }
    },
    data: () => ({
        tableLoading: false,
        otherLessonTableLoading: false,
        exportLoading: false,
        tutorOptions: [],
        classroomOptions: [],
        staticLessonInfo: {
            course_id: -1,
            course_name: '',
            course_code: '',
            date: '',
            lesson_type: '',
            school_id: null,
            grade: ''
        },

        formData: {
            date: '',
            start_time: '',
            end_time: '',
            tutor_id: '',
            classroom_id: '',
            attachment_list: [],
        },
      
        studentTableData: [],

        otherLessonTableData: [],
        otherLessonSelectedRow: [],
        otherLessonData: null,

     
        editIsRequired: false,
        editFormData: {
            start_time: '',
            end_time: '',
            tutor_id: '',
            classroom_id: '',
        },
        editedLessonIds: [],
        deletedLessonIds: [],

        isSearching: false,
        searchTableData: [],
        searchTableTotal: 0,
        searchTableOptions: {
            limitOptions: [5, 10, 20],
            tableLimit: 5,
            tablePage: 1,
        },
        changeLessonDialogOpen: false,
        changeLessonStudentId: null,
        appliedLessonIds: [],
        changeLessonFilter: {
            type: '', // 'all' | 'single'
            keyword: '',
            keywordType: 'course_name',
            dateRange: [],
            date: '',
            tutor: '',
            grade: '',
        },
        changeLessonTutorOptions: [],


        // --- static data
        icon: {
            mdiClose,
            mdiFileTableOutline
        },
        studentTableHeaders: [
            { value: 'class_student_name', text: '學生名稱' },
            { value: 'attend_status', text: '出席狀態', width: 230 },
            { value: 'switch_from', text: '轉堂記錄' },
            { value: 'actionTransfer', text: '', align: 'end' }
        ],
        otherClassTableHeaders: [
            { value: 'course_name', text: '課程' },
            { value: 'weekday', text: '星期' },
            { value: 'full_date', text: '日期' },
            { value: 'timeslot', text: '時間' },
            { value: 'grade', text: '年級' },
            { value: 'current_per_max_student_num', text: '學生數量', hasUnit: true },
            { value: 'minActionEditAndDelete', text: '' }
        ],
        startTimeOptions: [],
        changeLessonHeaders: [
            { value: 'course_name', text: '收費項目' },
            { value: 'full_date', text: '日期', hasWeekday: true },
            { value: 'timeslot', text: '時間' },
            { value: 'grade', text: '年級' },
            { value: 'tutor', text: '導師' },
            { value: 'current_per_max_student_num', text: '人數' },
            { value: 'actionSelectChangeLesson', text: '', align: 'end'},
        ],
        searchTypeOptions: [
            { value: 'course_name', text: '課程名稱' },
            { value: 'course_code', text: '課程編號' },
        ],
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getLessonById() {
            this.tableLoading = true;
            try {
                const data = await this.$Fetcher.GetLessonById(this.id, { 
                    join_student_data: true,
                    join_attendance_data: true
                 });
                for(const key in this.formData) {
                    if (this.$validate.DataValid(data[key])) {
                        this.formData[key] = data[key];
                    }
                }

                for(const key in this.staticLessonInfo) {
                    if (this.$validate.DataValid(data[key])) {
                        this.staticLessonInfo[key] = data[key];
                    }
                }

                if (this.$validate.DataValid(data.attendance_list)) {
                    this.studentTableData = data.attendance_list.map(el => {
                        return {
                            id: el.student_id,
                            class_student_name: el.student_name,
                            attend_status: el.status,
                            gender: el.gender,
                            switch_from: el.switch_from ? el.switch_from : 'N/A'
                        }
                    })
                }

                this.getOtherLessons();
                this.getTutorOptions();
                this.getClassroomOptions();
            } catch(err) {
                this.$common.error(err);
                this.setDialogMessage({
                    message: 'noData',
                    returnLink: { name: 'Timetable' }
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
                this.tableLoading = false;
            }
        },
        
        getOtherLessonsPayload() {
            return {
                center_id: this.staticLessonInfo.school_id,
                filter_item: [
                    { key: 'course_code', value: this.staticLessonInfo.course_code },
                    { key: 'course_id', value: this.staticLessonInfo.course_id }
                ]
            }
        },

        async getOtherLessons() {
            this.otherLessonTableLoading = true;
            try {
                const payload = await this.getOtherLessonsPayload();
                const { data } = await this.$Fetcher.GetLessons(payload);
                this.otherLessonData = data.filter(el => el.id !== this.id);
                this.otherLessonTableData = this.otherLessonData.map(el => {
                    return {
                        id: el.id,
                        course_name: el.course_name,
                        weekday: this.$formatter.getDay(el.date),
                        date: el.date,
                        grade: el.grade,
                        start_time: el.start_time,
                        end_time: el.end_time,
                        current_student_num: el.current_student_num,
                        max_student: el.max_student,
                        tutor_id: el.tutor_id,
                        classroom_id: el.classroom_id,
                    }
                })
                this.$common.logData(this.otherLessonTableData, '課程其他課堂')
            } catch(err) {
                this.$common.error(err);
            } finally {
                this.otherLessonTableLoading = false;
            }
        },
        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }
           
            const valid = this.$refs['classInfoForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);
            const attendanceList = this.studentTableData.map(el => {
                return {
                    student_id: el.id,
                    status: el.attend_status
                }
            })

            const payload = {
                date: this.formData.date,
                start_time: this.formData.start_time,
                end_time: this.formData.end_time,
                tutor_id: this.formData.tutor_id,
                classroom_id: this.formData.classroom_id,
                attachment_list: this.formData.attachment_list,
                attendance_list: attendanceList
            };

            try {
                await this.$Fetcher.UpdateLesson(this.id, payload);

                if (this.editedLessonIds.length > 0 || this.deletedLessonIds.length > 0) {
                    this.saveOtherLesson();
                } else {
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('setLoading', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '儲存成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });
                }
            } catch(err) {
                this.$common.error(err);
                this.setDialogMessage({
                    title: "儲存失敗",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
                this.$store.dispatch('setLoading', false);
            }
        },
        async saveOtherLesson() {
            this.$store.dispatch('setLoading', true);
            let deleteError = null;
            let editError = null;

            if (this.deletedLessonIds.length > 0) {
                deleteError = await this.deleteOtherLesson(0, []);
            }

            if (this.editedLessonIds.length > 0) {
                editError = await this.editOtherLesson(0, []);
            }

            if (this.$validate.DataValid(deleteError) || this.$validate.DataValid(editError)) {
                const errorLessons = [];
                this.deletedLessonIds.forEach(id => {
                    const target = this.otherLessonData.find(el => el.id === id);
                    if (target) {
                        errorLessons.push(`${target.course_name} (${target.date})`);
                    }
                })
                this.editedLessonIds.forEach(id => {
                    const target = this.otherLessonData.find(el => el.id === id);
                    if (target) {
                        errorLessons.push(`${target.course_name} (${target.date})`);
                    }
                })
               

                this.setDialogMessage({
                    title: "以下課程出現錯誤",
                    message: errorLessons.join('<br>'),
                    isError: true,
                    returnLink: { reload: true },
                });
                this.setShowDialog(true);
                this.$store.dispatch('setLoading', false);
            } else {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('setDataIsUpdated', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '儲存成功',
                    type: 'success',
                    refresh: true,
                    redirect: ''
                });
            }
        },
        async deleteOtherLesson(count, errorList) {
            if (count < this.deletedLessonIds.length) {
                try {
                    await this.$Fetcher.DeleteLesson(this.deletedLessonIds[count]);
                } catch(err) {
                    this.$common.error(err);
                    errorList.push(this.deletedLessonIds[count]);
                } finally {
                    if (count === this.deletedLessonIds.length - 1) {
                        return errorList;
                    } else {
                        return this.deleteOtherLesson((count + 1), errorList);
                    }
                }
            } else {
                return errorList;
            }
        },
        async editOtherLesson(count, errorList) {
            if (count < this.editedLessonIds.length) {
                const target = this.otherLessonTableData.find(el => el.id === this.editedLessonIds[count]);
                if (target) {
                    try {
                        const payload = {
                            start_time: target.start_time,
                            end_time: target.end_time,
                            tutor_id: target.tutor_id,
                            classroom_id: target.classroom_id
                        };

                        await this.$Fetcher.UpdateLesson(target.id, payload);
                    } catch(err) {
                        this.$common.error(err);
                        errorList.push(this.editedLessonIds[count]);
                    } finally {
                        if (count === this.editedLessonIds.length - 1) {
                            return errorList;
                        } else {
                            return this.editOtherLesson((count + 1), errorList);
                        }
                    }
                } else {
                    errorList.push(this.editedLessonIds[count]);
                    if (count === this.editedLessonIds.length - 1) {
                        return errorList;
                    } else {
                        return this.editOtherLesson((count + 1), errorList);
                    }
                }
            } else {
                return errorList;
            }
        },

        async getTutorOptions() {
            try {
                let payload = {};
                if (this.$validate.DataValid(this.staticLessonInfo.school_id)) {
                    payload.center = [this.staticLessonInfo.school_id];
                }

                const { data } = await this.$Fetcher.GetTutors(payload);
                this.tutorOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: el.name
                    }
                })

                this.changeLessonTutorOptions = [
                    { value: -1, text: '所有導師' },
                    ...this.tutorOptions
                ]
                
            } catch(err) {
                this.$common.error(err);
            }
        },

        async getClassroomOptions() {
            try {
                let payload = {};
                if (this.$validate.DataValid(this.staticLessonInfo.school_id)) {
                    payload.center_id = this.staticLessonInfo.school_id;
                }

                const { data } = await this.$Fetcher.GetClassrooms(payload);
                this.classroomOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: el.name
                    }
                })
                
            } catch(err) {
                this.$common.error(err);
            }
        },

        // ======= 課程其他課堂 =======
        async handleSelectActionChange(action) {
            if (!this.$validate.DataValid(this.otherLessonTableData)) {
                return;
            }
            if (action === 'edit') {
                if (!this.$validate.DataValid(this.otherLessonSelectedRow)) {
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '請選擇至少一個課堂進行編輯',
                        type: 'error',
                        refresh: false,
                        redirect: ''
                    });
                    return;
                }
                this.handleEditOpen();
            } else if (action === 'delete') {
                if (!this.$validate.DataValid(this.otherLessonSelectedRow)) {
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '請選擇至少一個課堂進行刪除',
                        type: 'error',
                        refresh: false,
                        redirect: ''
                    });
                    return;
                }
               this.handDeleteLesson();
            }
        },
        async handDeleteLesson(id) {
            const confirm = await this.$refs.deleteOtherClassDialog.show();
            if (confirm) {
                if (this.$validate.DataValid(id)) {
                    const targetEditPos = this.editedLessonIds.findIndex(el => el === id);
                    if (targetEditPos >= 0) {
                        this.editedLessonIds.splice(targetEditPos, 1);
                    }

                    const targetPos = this.otherLessonTableData.findIndex(el => el.id === id);
                    if (targetPos >= 0) {
                        this.otherLessonTableData.splice(targetPos, 1);
                        this._updateDeleteLessonList(id);
                    }
                    
                    this.$store.dispatch('setDataIsUpdated', true);
                } else {
                    this.otherLessonTableData = this.otherLessonTableData.filter(el => {
                        return !this.otherLessonSelectedRow.find(e => el.id === e.id);
                    })
                    this.editedLessonIds = this.editedLessonIds.filter(el => {
                        return !this.otherLessonSelectedRow.find(e => el === e.id);
                    })

                    this.otherLessonSelectedRow.forEach(el => {
                        this._updateDeleteLessonList(el.id);
                    })
                    this.otherLessonSelectedRow = [];
                    this.$store.dispatch('setDataIsUpdated', true);
                }
            }
        },
        async handleEditOpen(id) {
            if (this.$store.getters.isLoading || !this.$validate.DataValid(id) && !this.$validate.DataValid(this.otherLessonSelectedRow)) {
                return;
            }
  
            this.editFormData.start_time = '';
            this.editFormData.end_time = '';
            this.editFormData.tutor_id = '';
            this.editFormData.classroom_id = '';
            await this.$refs['editFormDialog'].$refs['editForm'].reset();
      
            if (this.$validate.DataValid(id)) {
                this.$refs['editFormDialog'].setEditId(id);
                this.editIsRequired = true;

                const target = this.otherLessonTableData.find(el => el.id === id);
                const dupTarget = this.$common.duplicateData(target);
                if (dupTarget) {
                    this.editFormData.start_time = dupTarget.start_time;
                    this.editFormData.end_time = dupTarget.end_time;
                    this.editFormData.tutor_id = dupTarget.tutor_id;
                    this.editFormData.end_time = dupTarget.end_time;
                    this.editFormData.classroom_id = dupTarget.classroom_id;
                }  
            } else {
                this.$refs['editFormDialog'].setEditId(null);
                this.editIsRequired = false;
            }

            this.$refs['editFormDialog'].show();
        },
        handleEditLessonSubmit() {
            const id = this.$refs['editFormDialog'].getEditId();

            if (this.$validate.DataValid(id)) {
                const target = this.otherLessonTableData.find(el => el.id === id);
                if (target) {
                    target.start_time = this.editFormData.start_time;
                    target.end_time = this.editFormData.end_time;
                    target.tutor_id = this.editFormData.tutor_id;
                    target.classroom_id = this.editFormData.classroom_id;
                }
                this._updateEditLessonList(id);
                this.$store.dispatch('setDataIsUpdated', true);
            } else if (this.$validate.DataValid(this.otherLessonSelectedRow)) {
                this.otherLessonSelectedRow.forEach(item => {
                    const targetItem = this.otherLessonTableData.find(el => el.id === item.id);
                    if (targetItem) {
                        if (this.$validate.DataValid(this.editFormData.start_time) && this.$validate.DataValid(this.editFormData.end_time)) {
                            targetItem.start_time = this.editFormData.start_time;
                            targetItem.end_time = this.editFormData.end_time;
                        }

                        if (this.$validate.DataValid(this.editFormData.tutor_id)) {
                            targetItem.tutor_id = this.editFormData.tutor_id;
                        }

                        if (this.$validate.DataValid(this.editFormData.classroom_id)) {
                            targetItem.classroom_id = this.editFormData.classroom_id;
                        }

                        this._updateEditLessonList(targetItem.id);
                    }
                })
                this.$store.dispatch('setDataIsUpdated', true);
            }

            this.$refs['editFormDialog'].hide();
        },
        _updateEditLessonList(id) {
            if (this.editedLessonIds.length > 0) {
                const target = this.editedLessonIds.find(el => el === id);
                if (!target) {
                    this.editedLessonIds.push(id);
                }
            } else {
                this.editedLessonIds.push(id);
            }
        },
        _updateDeleteLessonList(id) {
            if (this.deletedLessonIds.length > 0) {
                const target = this.deletedLessonIds.find(el => el === id);
                if (!target) {
                    this.deletedLessonIds.push(id);
                }
            } else {
                this.deletedLessonIds.push(id);
            }
        },

        // ======= 轉堂/轉堂(單一課堂) =======
        async changeLesson(type, student_id) {
            this.changeLessonFilter.type = type;
            this.changeLessonStudentId = student_id;
            this.changeLessonFilter.grade = '';
            this.changeLessonFilter.keyword = '';
            this.changeLessonFilter.dateRange = [];
            this.changeLessonFilter.date = '';
            this.changeLessonFilter.tutor = '';
            this.searching = true;
            this.searchTableOptions.tablePage = 1;
            this.searchTableData = [];
            this.searchTableTotal = 0;
            this.changeLessonDialogOpen = true;
            
            // find student applied lesson
            try {
                const { data } = await this.$Fetcher.GetLessons({ filter_item: [{ key: 'student_id', value: student_id }]});
                this.appliedLessonIds = data.map(el => el.id);
            } catch(err) {
                this.$common.error(err);
            } finally {
                this.searchLesson();
            }
        },

        async searchLesson() {
            this.searching = true;
            const payload = {
                filter_limit: this.searchTableOptions.tableLimit,
                filter_page: this.searchTableOptions.tablePage - 1,
                center_id: this.staticLessonInfo.school_id,
                join_tutor_data: true,
                filter_item: [],
            }

            if (this.$validate.DataValid(this.changeLessonFilter.tutor) && this.changeLessonFilter.tutor > -1) {
                payload.filter_item.push({ key: 'staff_id', value: this.changeLessonFilter.tutor });
            }

            if (this.$validate.DataValid(this.changeLessonFilter.keywordType) && this.$validate.DataValid(this.changeLessonFilter.keyword)) {
                payload.filter_item.push({ key: this.changeLessonFilter.keywordType, value: this.changeLessonFilter.keyword });
            }
            if (this.$validate.DataValid(this.changeLessonFilter.grade)) {
                payload.filter_item.push({ key: 'grade', value: this.changeLessonFilter.grade });
            }
            if (this.changeLessonFilter.type === 'all') {
                payload.filter_item.push({ key: 'lesson_type', value: 'regular' });
                if (this.$validate.DataValid(this.changeLessonFilter.dateRange)) {
                    if (this.changeLessonFilter.dateRange[0]) {
                        payload.filter_item.push({ key: 'start_date', value: this.changeLessonFilter.dateRange[0] });
                    }
                    if (this.changeLessonFilter.dateRange[1]) {
                        payload.filter_item.push({ key: 'end_date', value: this.changeLessonFilter.dateRange[1] });
                    }
                }
            } else if (this.changeLessonFilter.type === 'single') {
                if (this.$validate.DataValid(this.changeLessonFilter.date)) {
                    payload.filter_item.push({ key: 'date', value: this.changeLessonFilter.date });
                }
            }

            try {
                const { data, total } = await this.$Fetcher.GetLessons(payload);
                this.searchTableTotal = total;
                this.searchTableData = data.map(el => {
                    return {
                        id: el.id, // lesson id
                        lesson_type: el.lesson_type,
                        course_id: el.course_id,
                        course_code: el.course_code,
                        course_name: el.course_name,
                        date: el.date,
                        start_time: el.start_time,
                        end_time: el.end_time,
                        grade: el.grade,
                        tutor: el.tutor_data.name,
                        current_student_num: el.current_student_num,
                        max_student: el.max_student,
                        applied: this.$validate.DataValid(this.appliedLessonIds) && this.appliedLessonIds.includes(el.id),
                        same: this.changeLessonFilter.type === 'all' && el.course_code === this.staticLessonInfo.course_code,
                    }
                });
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.isSearching = false;
            }
        },

        onChangeLessonFilterChange() {
            this.searchTableOptions.tablePage = 1;
            this.searchTableTotal = 0;
            this.searchLesson();
        },

        async handleChangeLesson(lesson_id) {
            if (this.appliedLessonIds.includes(lesson_id)) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '已報讀此課堂，不可重覆報讀',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const targetLesson = this.searchTableData.find(el => el.id === lesson_id);
            this.$common.logData(targetLesson, '轉堂');
            if (this.$validate.DataValid(targetLesson)) {
                if (targetLesson.current_student_num >= targetLesson.max_student) {
                    const confirm = await this.$refs.exceedStudentDialog.show();
                    if (!confirm) {
                        return;
                    }
                } else {
                    const confirm = await this.$refs.changeLessonRemindDialog.show();
                    if (!confirm) {
                        return;
                    }
                }

                this.$store.dispatch('setLoading', true);
                if (this.changeLessonFilter.type === 'all') {
                    try {
                        await this.$Fetcher.SwitchCourse(this.changeLessonStudentId, this.staticLessonInfo.course_code, targetLesson.course_code, targetLesson.date);
                        this.$store.dispatch('toggleAlertMessage', {
                            show: true,
                            message: '轉堂成功',
                            type: 'success',
                            refresh: true,
                            redirect: ''
                        });
                        this.changeLessonDialogOpen = false;
                    } catch(err) {
                        this.$common.error(err);
                        this.setDialogMessage({
                            title: "轉堂失敗",
                            message: err,
                            isError: true,
                            returnLink: null,
                        });
                        this.setShowDialog(true);
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                } else if (this.changeLessonFilter.type === 'single') {
                    try {
                        await this.$Fetcher.SwitchLesson(this.changeLessonStudentId, this.id, targetLesson.id);
                        this.$store.dispatch('toggleAlertMessage', {
                            show: true,
                            message: '轉堂成功',
                            type: 'success',
                            refresh: true,
                            redirect: ''
                        });
                        this.changeLessonDialogOpen = false;
                    } catch(err) {
                        this.$common.error(err);
                        this.setDialogMessage({
                            title: "轉堂失敗",
                            message: err,
                            isError: true,
                            returnLink: null,
                        });
                        this.setShowDialog(true);
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            } else {
                this.changeLessonDialogOpen = false;
            }
        },

        onSearchTableOptionsChange(options) {
            if (options.itemsPerPage !== this.searchTableOptions.tableLimit) {
                this.searchTableOptions.tablePage = 1;
            } else {
                this.searchTableOptions.tablePage = options.page;
            }

            this.searchTableOptions.tableLimit = options.itemsPerPage;
            this.searchLesson();
        },

        // ======= 退課 =======
        async dropCourse(studentId) {
            const confirm = await this.$refs.dropCourseRemindDialog.show();

            if (confirm) {
                this.$store.dispatch('setLoading', true);
                try {
                    await this.$Fetcher.DropCourse(studentId, this.staticLessonInfo.course_code, this.staticLessonInfo.date);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '退課成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });
                } catch(err) {
                    this.$common.error(err);
                    this.setDialogMessage({
                        title: "退課失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
          
        },

        // ========= 匯出報表 =========
        async exportAttendance() {
            if (this.exportLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.exportLoading = true;

            try {
                const payload = await this.getOtherLessonsPayload();
                const { data } = await this.$Fetcher.GetLessons({
                    join_student_data: true,
                    ...payload
                });

                data.sort((a,b) => {
                    const aDate = this.$formatter.convertStrToDate(a);
                    const bDate = this.$formatter.convertStrToDate(b);
                    return aDate.getTime() - bDate.getTime();
                })

                this.$common.logData(data, '匯出報表');

                let header = {
                    student_name: '',
                }

                data.forEach(el => {
                    const dateString = `${el.date.substring(8,10)}/${el.date.substring(5,7)}/${el.date.substring(0,4)}`;
                    header[el.date] = dateString;
                })

                const students = [];
                data.forEach(el => {
                    el.all_attendance_list.forEach(student => {
                        const existed = students.find(e => e.id === student.student_id);
                        if (!existed) {
                            students.push({
                                id: student.student_id,
                                name: student.student_name
                            })
                        }
                    })
                })

                const exportData = students.map(el => {
                    let obj = {};
                    for (const key in header) {
                        if (key === 'student_name') {
                            obj['student_name'] = el.name;
                        } else {
                            let status = '';
                            const targetLesson = data.find(item => item.date === key);
                            if (targetLesson) {
                                const targetAttendance = targetLesson.all_attendance_list.find(e => e.student_id === el.id);
                                if (targetAttendance) {
                                    switch(targetAttendance.status) {
                                        case 'attend':
                                            status = 'Y';
                                            break;
                                        case 'absent':
                                            status = 'Absent';
                                            break;
                                        case 'drop':
                                            status = 'Drop';
                                            break;
                                        case 'swap':
                                            status = 'Swap';
                                            break;
                                        case 'reschedule':
                                            status = 'Reschedule';
                                            break;
                                    }
                                }
                            }
                            obj[key] = status;
                        }
                    }

                    return obj;
                })

                this.exportExcel(
                    header,
                    exportData,
                    '學生出席狀態',
                    `${this.staticLessonInfo.course_code}_${this.staticLessonInfo.course_name}_學生出席狀態.xlsx`,
                );
            } catch(err) {
                this.$common.error(err);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '匯出失敗',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
            } finally {
                this.exportLoading = false;
            }
        },
    },
    async mounted() {
        this.$store.dispatch('toggleLoadingPage', true);
        if (this.$validate.DataValid(this.id)) { // edit
            await this.getLessonById();
        }
    },

    created() {
        this.startTimeOptions = this.$common.getStartTimeOptions();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>

<style lang="scss" scoped>
.course-info {
    & > .v-card__title {
        background-color: #ffffff !important;
        color: var(--v-colorBlack-base) !important;
        font-size: .875rem;
        font-weight: 500;
    }

    & .course-code {
        background-color: var(--v-primary-base);
        color: var(--v-colorWhite-base) !important;
        font-size: .875rem;
        font-weight: 500;
    }
}



</style>